function getRadioVal(radios) {
  for (var i = 0; i < radios.length; i++){
    if (radios[i].checked){
      return radios[i].value;
    }
  }
}

function onEmailSignupSubmit(e) {
  const frm = e.target;
  const container = frm.parentNode;
  e.preventDefault();
  const os = getRadioVal($$('input[name=os]', frm));
  const who = $('select[name=who]', frm).value;
  const email = $('input[type=email]', frm).value;

  // TODO: Use line below instead for credit card signup.
  // window.location = `start.html#email=${encodeURIComponent(email)}`;
  // return;

  // const url = 'https://pingy-d24ad.appspot.com/mailing-list/signup';
  // const url = 'http://localhost:8080/mailing-list/signup';
  const url = 'https://s4ekhmqtz2.execute-api.us-east-1.amazonaws.com/dev/addToNotifyList'
  // const url = 'http://localhost:4001/addToNotifyList';
  const responseEl = $('.email-signup-response', container);
  const buttonEl = $('button', frm);
  const negativeEl = $('.negative-response', responseEl);
  const positiveEl = $('.positive-response', responseEl)
  buttonEl.setAttribute('disabled', '');
  positiveEl.style.display = 'none';
  negativeEl.style.display = 'none';

  $.fetch(url, {
    method: 'POST',
    data: JSON.stringify({
      email: email,
      vars: { os, who }
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(() => {
    positiveEl.style.display = 'block';
    $('input[type=email]', frm).value = '';
    buttonEl.removeAttribute('disabled');
    ga('send', 'event', 'Beta Mailing List', 'signup');
  }).catch(err => {
    const msg =
      err.xhr &&
      err.xhr.response &&
      JSON.parse(err.xhr.response) &&
      JSON.parse(err.xhr.response).message || err.message;
    $('span', negativeEl).textContent = msg.replace('Error: ', '');
    negativeEl.style.display = 'block';
    buttonEl.removeAttribute('disabled');
  })
}

$$('form.notify-signup').forEach(frm =>
  frm.addEventListener('submit', onEmailSignupSubmit)
)
